import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
// import utils from '../utils';
import UpFrontContent from './UpFrontContent';
import utils from '../utils';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

class Hello extends React.Component {
    constructor(props) {
        super(props);

        utils.fetchPage(process.env.REACT_APP_API_HELLO, this);

        gsap.registerPlugin(ScrollTrigger, SplitText);
    }

    componentDidMount() {
        this.didMount = true;
    }

    componentDidUpdate() {
        if(!this.json) return;
        if(!this.anim) this.anim = gsap.from(".top-div p", { delay: 0.25, duration: 0.5, y: '20%', autoAlpha: 0, ease: "power3", stagger: 0.1 })
    }

    render() {

        if (this.json) return <UpFrontContent id="hello" title={this.json.title.rendered} content={this.json.content.rendered} />

        return <UpFrontContent />

    }
}

export default withRouter(Hello);