import React, { Component } from 'react'
import UpFrontContent from './UpFrontContent'
import utils from '../utils';
import gsap from "gsap";
import SplitText from "gsap/SplitText";

export default class Contact extends Component {

    formAction = null;

    constructor(props) {
        super(props);

        this.refDiv = React.createRef();
        window.submitForm = this.submitForm.bind(this);
        window.wpcf7 = { "apiSettings": { "root": `${process.env.REACT_APP_WP_URL}${process.env.REACT_APP_REST_ROUTE}/contact-form-7/v1`, "namespace": "contact-form-7/v1" } };

        utils.fetchPage(process.env.REACT_APP_API_CONTACT, this);
    }

    forEachFormEle(cb) {
        const eleTypes = ['input', 'textarea'];

        eleTypes.forEach((o) => {
            document.querySelectorAll(o).forEach((p) => cb(p))
        })
    }

    submitForm() {
        const actionUrl = process.env.REACT_APP_WP_URL + this.formAction;

        const obj = {};

        this.forEachFormEle((p) => obj[p.name] = p.value);

        /**
         * per honey pot ajax guidelines @jkr
         * https://conditional-fields-cf7.bdwm.be/ajax-example/
         */
        obj.action = 'load_form';
        obj.form_id = obj._wpcf7; // obj.form_id = document.querySelector('*[name="_wpcf7"]').value;


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ email: fromEmail, message: message })
            body: JSON.stringify(obj)
        };

        // console.log(requestOptions);

        const form = document.querySelector('.wpcf7-form');
        window.wpcf7.initForm(form);
        // window.wpcf7cf.initForm(form);

        // fetch(`${action}?z=1&email=${fromEmail}&message=${message}`, requestOptions)
        fetch(actionUrl, requestOptions)
            .then((res) => {

                switch (res.status) {
                    case 200:
                        // clear the input
                        this.forEachFormEle((p) => {
                            switch (true) {
                                case p.tagName.toLowerCase() === 'textarea':
                                case p.type = 'email':
                                    p.value = '';
                                    break;
                                default: break;
                            }
                        });
                        break;
                    default:
                        this.formError(res.status);
                        break;
                }

                console.log(res)
            })
            .catch((e) => {
                console.warn(e);
                this.formError(e);
            })

        return false;
    }

    formError(status) {
        console.warn(status);
        // window.alert('Double check your work and send again!');
    }

    componentDidMount() {
        this.didMount = true;

        // add honeypot scripts (requires jquery) @jkr
        try {
            const div = this.refDiv.current;
            const script = document.createElement("script");
            script.async = true;
            script.src = `${process.env.REACT_APP_WP_URL}/wp-content/plugins/contact-form-7/includes/js/scripts.js?ver=5.1.7`;
            div.appendChild(script);
        } catch (e) {
            console.warn(e);
        }
    }

    componentDidUpdate() {
        if (this.json) {
            var split = new SplitText(".top-div p", { type: "chars,words,lines", position: "absolute" });
            gsap.from(split.lines, { delay: 0.3, duration: 0.5, y: '30%', autoAlpha: 0, ease: "power3", stagger: 0.1 });
        }
    }

    refactorForm(html) {

        /**
        
        <div role="form" class="wpcf7" id="wpcf7-f868-o1" lang="en-US" dir="ltr">
        <div class="screen-reader-response"></div>
        <form action="/?rest_route=%2Fwp%2Fv2%2Fpages%2F793#wpcf7-f868-o1" method="post" class="wpcf7-form" novalidate="novalidate">
        <div style="display: none;">
        <input type="hidden" name="_wpcf7" value="868" />
        <input type="hidden" name="_wpcf7_version" value="5.1.7" />
        <input type="hidden" name="_wpcf7_locale" value="en_US" />
        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f868-o1" />
        <input type="hidden" name="_wpcf7_container_post" value="0" />
        </div>
        <p><span class="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="user@email.org" /></span><br />
        <span class="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Tell us about your project..."></textarea></span><br />
        <input type="submit" value="Send" class="wpcf7-form-control wpcf7-submit" /><br />
        <span id="wpcf7-5fe52f1b37fbd" class="wpcf7-form-control-wrap honeypot-156-wrap" style="display:none !important; visibility:hidden !important;"><label  class="hp-message">Please leave this field empty.</label><input class="wpcf7-form-control wpcf7-text"  type="text" name="honeypot-156" value="" size="40" tabindex="-1" autocomplete="nope" /></span></p>
        <div class="wpcf7-response-output wpcf7-display-none"></div></form></div>
    
         */

        try {
            const formRE = /action="([a-z0-9-=%_/?#]+)"/i
            const m = html.match(formRE);
            this.formAction = m[1];
            // console.log(m)
            html = html.replace(formRE, `action="return false;" onSubmit="window.submitForm(); return false;"`);
        } catch (e) {
            console.warn(e);
        }

        return html;
    }

    render() {


        if (!this.json) return (
            <div ref={this.refDiv}>
                <UpFrontContent />
            </div>
        )

        const form = this.refactorForm(this.json.content.rendered);
        return (
            <div ref={this.refDiv}>
                <UpFrontContent id='contact' title={this.json.title.rendered} content={form} />
            </div>
        )

    }
}
