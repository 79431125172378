import React, { Component } from 'react'
import UpFrontContent from './UpFrontContent';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import utils from '../utils';

export default class Portfolio extends Component {

    /**
     * wanting to use sticky roll for this at some point
     * https://stickyroll.github.io/react-stickyroll/ 
     * @jkr
     */

    constructor(props) {
        super(props);

        this.state = {
            itemsList: undefined
        }

        gsap.registerPlugin(ScrollTrigger);
    }

    get itemId() {
        const itemId = this.props.match.params.itemId;
        if (isNaN(+itemId) && this.state.itemsList) return Object.keys(this.state.itemsList)[0];

        return itemId;
    }

    get nextItemId() {
        const keys = Object.keys(this.state.itemsList);
        const itemId = this.itemId;
        const itemIdx = keys.indexOf(itemId);
        const nextId = keys[itemIdx + 1];
        return nextId || keys[0];
    }

    set portfolioItems(value) {
        this.setState({ porfolioData: value, activeIndex: 0 });
    }

    componentDidMount() {
        this.fetchItemList();
    }

    componentWillUnmount() {
        if (this.scrollTrigger) this.scrollTrigger.kill();
    }

    componentDidUpdate() {
        if (!this.state.itemsList) return this.fetchItemList();

        try {
            if (this.scrollTrigger) this.scrollTrigger.kill();
            const itemId = this.itemId;
            const content = this.state.itemsList[itemId].content;
            if (!content) throw new Error('need to fetch item');

            this.scrollTrigger = ScrollTrigger.create({
                trigger: ".next-item",
                start: "middle",
                // markers: true, // only during development
                // onUpdate: self => {
                //     console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                // },
                onToggle: self => {
                    if (self.direction > 0) {
                        const url = `/portfolio/${this.nextItemId}`;
                        this.props.history.push(url);
                        this.scrollTrigger.scroll(0)
                    }
                }
            });
        } catch (e) {
            return this.fetchItem(this.itemId);
        }
    }

    //todo: write new wp/json/portfolio generic endpoint for this with less bloat (only id's & titles) @jkr
    fetchItemList() {
        fetch(utils.restApiBase + process.env.REACT_APP_API_PORTFOLIO).then((res) => {
            res.json().then((json) => {
                const itemsList = {};
                json.forEach((item) => {
                    itemsList[item.id] = { title: item.title };
                });
                this.setState({ itemsList: itemsList });
            });
        }).catch((err) => {
            this.setState({ itemsList: null });
            console.warn(err)
        })
    }

    fetchItem(id) {
        if (!id) id = this.itemId;
        fetch(`${utils.restApiBase}${process.env.REACT_APP_API_PORTFOLIO}/${id}`).then((res) => {
            res.json().then((json) => {
                const itemsList = this.state.itemsList;
                itemsList[json.id] = json;
                this.setState({ itemsList: itemsList });
            });
        }).catch((err) => {
            this.setState({ itemsList: null });
            console.warn(err)
        })
    }

    render() {
        switch (true) {
            case !this.state.itemsList:
            case !this.state.itemsList[this.itemId]:
            case !this.state.itemsList[this.itemId].content:
                return <UpFrontContent />
            default: break;
        }

        const item = this.state.itemsList[this.itemId];
        const next = this.state.itemsList[this.nextItemId];

        return (
            <>
                <UpFrontContent className={`portfolio-item`} title={item.title.rendered} content={item.content.rendered} />
                <UpFrontContent className={'next-item'} title={next.title.rendered} />
                <div>&nbsp;</div>{/** this spacer helps with the trigger @jkr */}
            </>
        )
    }
}
