import React, { Component } from 'react'
import UpFrontContent from './UpFrontContent'
import utils from '../utils';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

export default class About extends Component {
    constructor(props) {
        super(props);

        utils.fetchPage(process.env.REACT_APP_API_ABOUT, this);

        gsap.registerPlugin(ScrollTrigger, SplitText);

        // gsap.to("#hello", {
        //     scrollTrigger: "#hello", // start the animation when ".box" enters the viewport (once)
        //     x: 500
        // });

        // ScrollTrigger.create({
        //     trigger: "#hello",
        //     start: "top center",
        //     end: "+=500",
        //     onToggle: self => console.log("toggled. active?", self.isActive)
        // });
    }

    componentDidMount() {
        this.didMount = true;
    }

    componentDidUpdate() {
        if(!this.json) return;

        //create a SplitText instance for the element with ID "yourElementID" that splits apart characters, words, and lines, and uses absolute positioning:
        if(!this.split) this.split = new SplitText(".top-div p", { type: "words,lines", position: "absolute" });
        //now animate each character into place from 100px above, fading in:
        // gsap.from(split.chars, { delay: 0.25, duration: 0.25, x: '100%', y: '-25%', autoAlpha: 0, stagger: 0.01 });
        //or animate each word
        // gsap.from(split.words, { duration: 1, x: 200, autoAlpha: 0, ease: "elastic", stagger: 0.05 });
        //or animate each line
        if(!this.anim) this.anim = gsap.from(this.split.lines, { delay: 0.3, duration: 0.5, y: '30%', autoAlpha: 0, ease: "power3", stagger: 0.1 });
    }

    render() {

        if (this.json) return <UpFrontContent title={this.json.title.rendered} content={this.json.content.rendered} />

        return <UpFrontContent />

    }
}
