import React from 'react'
import { withRouter } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import utils from '../utils';
import gsap from "gsap";
import SplitText from "gsap/SplitText";

const pages = [
    { id: "Hello", title: { rendered: "Hello" }, slug: "hello" },
    { id: "Portfolio", title: { rendered: "Portfolio" }, slug: "portfolio" },
    { id: "About", title: { rendered: "About" }, slug: "about" },
    { id: "Contact", title: { rendered: "Contact" }, slug: "contact" },
];

class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = { open: false }

        // window.menuToggle = this.menuToggle.bind(this);
    }

    menuToggle(event) {
        event.preventDefault();

        const cur = this.state;
        cur.open = !cur.open;
        this.setState(cur);
    }

    componentDidMount() {
        var split = new SplitText(".id-menu a.z-index-fixed", { type: "chars", position: "relative" });
        gsap.from(split.chars, { delay: 1.75, duration: 0.5, y: '35%', autoAlpha: 0, stagger: 0.1 });
    }

    render() {

        const display = this.state.open ? '' : 'd-none';

        return (
            <div className={'id-menu sticky-top float-right'} style={{ right: 0 }}>
                <Row noGutters>
                    <Col className={'mt-5 mr-5'}>
                        <a className={'z-index-fixed'} onClick={this.menuToggle.bind(this)} href={'#menu'}>Menu</a>
                    </Col>
                </Row>
                <Row noGutters className={`fixed-top h-100 bg-dark ${display}`} onClick={this.menuToggle.bind(this)}>
                    <Col className={''}>
                        <Row className={`h-100 d-flex justify-content-center`}>
                            <Col className={`d-flex flex-column col-8 text-center justify-content-center`}>
                                <Row>
                                    <Col>
                                        {pages.map((page) => <a key={page.id} onClick={utils.linkClickHandler.bind(this)} href={`/${page.slug}`}><h1 className={'d-md-inline-block d-lg-block d-xl-inline-block m-2 text-uppercase'}>{page.title.rendered}</h1></a>)}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Menu)