import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
// import utils from '../utils';
// import TrEle from './TrEle';
import gsap from "gsap";
import SplitText from "gsap/SplitText";

// upfront content is kind of "front and center" or where the main content will be @jkr

/**
 * todo: clicking logo (top left) causes home title to reanimate @jkr
 */

export default class UpFrontContent extends Component {

    constructor() {
        super();

        this.state = { title: 'Loading' };

        this.refH1 = React.createRef();
    }

    doGsapAnimation() {
        const yv = this.props.title ? '100%' : '-100%';
        if (this.animation) this.animation.restart();
        if (this.split.chars.length > 0) {
            this.animation = gsap.from(this.split.chars, { duration: 0.5, y: yv, alpha: 0, stagger: 0.02 });
        }
    }
    doGsapSplit() {
        this.split = new SplitText(this.refH1.current, { type: "chars,words", position: "relative" });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.title !== this.state.title) this.setState({ title: this.props.title })

        this.doGsapSplit();
        this.doGsapAnimation();
    }
    componentDidMount() {
        this.doGsapSplit();
        this.doGsapAnimation();
        this.split = null;
    }

    render() {
        const title = this.props.title || this.state.title;

        return (
            <Row noGutters id={this.props.id} className={`vh100 ${this.props.className || ''}`}>
                <Col className={'d-flex align-items-center justify-content-center flex-column p-5'}>
                    {this.props.children &&
                        this.props.children
                    }

                    {this.props.children === undefined &&
                        <>
                            <div className={"top-title"}><h1 ref={this.refH1} dangerouslySetInnerHTML={{ __html: title }}></h1></div>
                            {this.props.content &&
                                <div className="top-div" dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
                            }
                        </>
                    }
                </Col>
            </Row >
        )
    }
}
