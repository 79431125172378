import React from 'react'
import utils from '../utils';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import gsap from 'gsap';

class Logo extends React.Component {
    componentDidMount() {
        gsap.from('.id-logo', { delay: 2.5, duration: 0.5, alpha: 0 })

    }
    render() {
        return (
            <Row noGutters className={'id-logo sticky-top float-left'}>
                <Col className={`mt-5 ml-5`}>
                    <a href="/" onClick={utils.linkClickHandler.bind(this)}>
                        <img src={require(process.env.REACT_APP_LOGO)} alt="logo" />
                    </a>
                </Col>
            </Row>
        )
    }
}

export default withRouter(Logo);