import React, { Component } from 'react';
import App from '../App.js';
import gsap from 'gsap';

class Splash extends Component {
    componentDidMount() {
        gsap.timeline({
            onComplete: () => {
                window.AppInstance.phase = App.Phase.MAIN
            }
        }).add('start')
            // .from('#splash img', { duration: 0.25, alpha: 0 })
            .to('#splash img', { ease: 'easeOut', delay: 0.2, duration: 0.4, y: '-10%' }, 'start') // img rise
            .to('#splash', { ease: 'linear', delay: 0.3, duration: 0.5, alpha: 0 }, 'start') // all fade out
    }
    render() {
        return (
            <div id="splash" className={`vh100`}>
                <img src={require(process.env.REACT_APP_LOGO)} alt="logo" />
            </div>
        );
    }
}

export default Splash;