const utils = {
    restApiBase: process.env.REACT_APP_WP_URL + process.env.REACT_APP_REST_ROUTE + '/wp/v2/',//'http://wordpress.bluernd.com/wp-json/wp/v2/',

    linkClickHandler: function (event) {
        event.preventDefault();

        const target = event.currentTarget;
        const href = target.getAttribute('href');

        this.props.history.push(href);
    },

    validateEmail: (str) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(str.toLowerCase());
    },

    invalidateFormElement: (ele, isInvalid = true) => {

        ele.classList.remove('invalid');

        if (isInvalid === true) {
            window.setTimeout(() => {
                ele.classList.add('invalid');
            }, 100);
        }
    },

    restartCssAnimationFor: (obj) => {
        if (typeof (obj) === 'string') obj = document.getElementsByClassName(obj); // if string, find els with that class @jkr

        const doTheThing = (el) => {
            if(!el.cloneNode) return;
            const cloned = el.cloneNode(true);
            const parent = el.parentElement;
            parent.insertBefore(cloned, el);
            el.remove();
        }

        if (obj.length) {
            Array(obj).forEach((o, i) => {
                doTheThing(obj[i])
            }); // loop through classLists @jkr
        } else
            doTheThing(obj); // when obj is single el @jkr
    },

    // todo: figure out why bind / apply do not work with this function - results in this===undefined @jkr
    fetchPage: (pageUrl, self) => {
        fetch(utils.restApiBase + pageUrl)
            .then((res) => res.json())
            .then((json) => {
                self.json = json;
                if (self.didMount === true) {
                    self.forceUpdate();
                    // utils.restartCssAnimationFor('trele');
                }
            })
    }
}


export default utils;