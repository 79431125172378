import React from 'react';
import { Switch, Route, withRouter } from "react-router-dom";
import Menu from "./components/Menu.js";
import Hello from "./components/Hello.js";
import Portfolio from "./components/Portfolio.js";
import About from "./components/About.js";
import Contact from "./components/Contact.js";
import "./style.scss";
import Logo from './components/Logo.js';
import utils from './utils.js';
import Splash from './components/Splash.js';
// import InteractiveBackground from './components/InteractiveBackground.js';

class App extends React.Component {

  static Phase = Object.freeze({
    SPLASH: 'App.Phase.SPLASH',
    MAIN: 'App.Phase.MAIN'
  });

  constructor() {
    super();

    window.AppInstance = this; // todo: make this right @jkr
    this.phase = App.Phase.SPLASH;
  }

  set phase(value) {
    const wasNull = !this._phase;
    this._phase = value;
    if(!wasNull) this.forceUpdate(); // don't run if this is the first assignment @jkr
  }
  get phase() {
    return this._phase;
  }

  componentDidMount() {
    // window.utils = {
    //   linkClickHandler: utils.linkClickHandler.bind(this)
    // }
    window.utils = utils;
    window.utils.linkClickHandler = utils.linkClickHandler.bind(this); // override @jkr

    // gsap.timeline({ onComplete: () => {this.phase = App.Phase.MAIN} })
    //   .from('#splash', { duration: 0.5, alpha: 0 })
    //   .to('#splash', { delay: 1, duration: 0.5, alpha: 0 })
  }

  render() {
    return (
      <div id="app">
        {this.phase === App.Phase.SPLASH &&
          <Splash />
        }
        {this.phase === App.Phase.MAIN &&
          <>
            {/* <InteractiveBackground /> */}
            <Logo />
            <Menu />
            <div id="router">
              <Switch>
                <Route exact path={["/portfolio", "/portfolio/:itemId"]} component={Portfolio} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route component={Hello} />
              </Switch>
            </div>
          </>
        }
      </div>
    )
  }
}

export default withRouter(App);